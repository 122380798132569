import { Application } from "@hotwired/stimulus";
import debounced from 'debounced';

const application = Application.start();
debounced.initialize();

declare global {
  interface Window {
    Stimulus: Application;
  }
}

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };
